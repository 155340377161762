import React from 'react'
import Layout from '../../components/layout';

export default () => {
  return (
    <Layout>
        <div className="page-layout prints-page">
            <div className="container">
                <h1>Merch</h1>
            </div>
        </div>
    </Layout>
  )
}
